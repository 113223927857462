// Here you can add other styles
a {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.table-data-cell {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Show only 2 lines of text */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
}
.loader-wrp {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1056;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
}
.account-heading {
  background: #d9d9d9;
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  @media (max-width: 767px) {
    display: block;
    text-align: center;
    margin-bottom: 0;
  }
}
.flex-half {
  flex: 0.6;
  @media (max-width: 767px) {
    flex: 0.9;
  }
}
.icon-format {
  background-color: #321fdb;
  border-radius: 50%;
  padding: 3px;
  color: #ffffff;
  cursor: pointer;
}
.btn-grey {
  background: #d9d9d9;
}
.icon-notif {
  color: #e96823;
}
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 70px;
}
.notif-dark {
  border-style: none;
  color: rgb(255, 255, 255);
  background-color: rgb(15, 15, 15);
}
.notif-white {
  border-style: none;
  color: rgb(10, 10, 10);
  background-color: #fff;
}
hr.solid {
  border-top: 3px solid #bbb;
}
.badge-item {
  border-radius: 50%;
  color: #fff;
  width: 1%;
}
.gr-1 {
  background: linear-gradient(170deg, #b4ec51 0%, #429321 100%);
}
.my-dropdown .dropdown-toggle::after {
  display: none;
}
.w-md-auto {
  @media (max-width: 599px) {
    width: 100%;
  }
}
.header-divider {
  flex-basis: auto;
}
.rewards-img {
  filter: grayscale(100%);
}
.cstm-datepicker {
  height: 38px;
  width: 100%;
  border: 1px solid #b1b7c1;
  padding: 6px 36px 6px 12px;
  border-radius: 0.375rem;
  color: #2c384a;
  opacity: 0.9;
}
.cstm-multiselect {
  padding-top: 20px;
}
.cstm-chip {
  background-color: #afc1de;
  padding: 8px;
  margin-right: 4px;
  border-radius: 10px;
}
.card {
  height: 100%;
}
.bg-selected {
  background: #fef5ee;
  td {
    &:nth-child(2) {
      background: #faddc7;
    }
  }
}
.pointer {
  &:hover {
    cursor: pointer;
  }
}
.bg-trending {
  background: #fce8e8;
}
.sidebar-narrow-unfoldable {
  .logo-section {
    padding: 10px !important;
  }
}
.avatar-section {
  img {
    width: 80px;
    height: 80px;
    cursor: pointer;
    border: 2px solid transparent;
  }
  .border {
    border: 2px solid #f00000 !important;
    border-radius: 50%;
  }
}
.fileUpload {
  label {
    padding: 20px 100px;
    border: 2px dashed #cccccc;
    background: #f6f8fc;
    border-radius: 5px;
    p {
      color: #c9cbd9;
      margin-bottom: 0;
      font-weight: bold;
    }
    input {
      display: none;
    }
  }
}
.img-preview {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.image-upload {
  position: relative;
  display: inline-block;
  svg {
    position: absolute;
    top: 0;
    right: 0;
    color: #f00000;
    cursor: pointer;
  }
}
.fs-12 {
  font-size: 12px;
}
.online-offline {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid #e9e9e9;
    padding: 10px;
    flex: 1;
    border-radius: 20px;
    text-align: center;
    color: #000000;
    text-decoration: none;
    &:first-child {
      margin-right: 10px;
      span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: green;
        display: block;
        margin-right: 5px;
      }
      &:hover {
        border-color: green;
        color: green;
      }
    }
    &:last-child {
      margin-left: 10px;
      span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: red;
        display: block;
        margin-right: 5px;
      }
      &:hover {
        border-color: #f00000;
        color: #f00000;
      }
    }
  }
}
.set-status {
  position: relative;
  .status-dropdown {
    position: absolute;
    background: #ffffff;
    top: 20px;
    left: 0;
    right: 0;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 1);
    box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 1);
    border-radius: 5px;
    .close-icon {
      position: absolute;
      top: -10px;
      right: -10px;
      color: #ffffff;
      background: #f00000;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .emoji {
    &:hover {
      color: blue;
      cursor: pointer;
    }
  }
  .emoji-section {
    position: relative;
    .EmojiPickerReact {
      position: absolute;
      top: 30px;
      right: 0;
      @media (max-width: 728px) {
        left: -75px;
      }
    }
    a {
      text-decoration: none;
    }
  }
}
.poll-choice {
  border: 2px solid #e9e9e9;
  padding: 20px;
  border-radius: 5px;
}
.poll-graph {
  padding: 20px;
  border-radius: 5px;
  .pic-stack {
    display: flex;
    img {
      width: 30px;
      height: 30px;
      margin-left: -10px;
      border: 2px solid #ffffff;
      border-radius: 50%;
    }
  }
  .poll-answers {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 0 10px;
    li {
      display: flex;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .total {
        font-weight: bold;
      }
      div {
        flex: 1;
        .percentage {
          width: 100%;
          height: 10px;
          border-radius: 20px;
          background: green;
        }
      }
    }
  }
}
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    outline: none;
    font-weight: bold;
    font-size: 1rem;
  }
  // Box.
  & + label:before {
    content: '';
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    background: #ffffff;
    border-radius: 50%;
    border: 2px solid #d7d7d7;
  }
  // Box hover
  &:hover + label:before {
    background: transparent;
  }
  // Box focus
  // &:focus + label:before {
  //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  // }
  // Box checked
  &:checked + label:before {
    background: #f35429;
    border: 2px solid #f35429;
  }
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 13px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}
.card-graph {
  position: relative;
  .graph-visualization {
    background-color: #fff;
    height: 100%;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    padding: 20px;
    transition: all 0.2s linear 0s;
    visibility: hidden;
  }
  .show {
    visibility: visible;
    z-index: 1;
  }
  .flex-wrap {
    grid-template-columns: repeat(auto-fill, 70px);
    a {
      width: 60px;
      height: 60px;
    }
  }
}
.offcanvas-theme {
  position: fixed;
  top: 100px;
  right: 0;
  z-index: 1044;
  background: #000000;
  padding: 5px 10px 2px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.hover-logo {
  div {
    background-color: #ffd503 !important;
  }
  &:hover {
    div {
      background-color: #ffd503 !important;
    }
  }
}

.cookieCard {
  /* width: 300px; */
  background: linear-gradient(to right, rgb(137, 104, 255), rgb(175, 152, 255));
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  /* min-height: 400px;
  max-height: 400px; */
}

.cookieCard::before {
  width: 150px;
  height: 150px;
  content: '';
  background: linear-gradient(to right, rgb(142, 110, 255), rgb(208, 195, 255));
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: -25%;
  top: -25%;
}

.cookieHeading {
  font-size: 1.5em;
  font-weight: 600;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.cookieDescription {
  font-size: 1.1em;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.cookieDescription a {
  color: rgb(241, 241, 241);
}

.acceptButton {
  padding: 11px 20px;
  background-color: #7b57ff;
  transition-duration: 0.2s;
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  z-index: 2;
  border-color: #f1f1f1;
  border-style: solid;
  border-width: thin;
}

.acceptButton:hover {
  background-color: #714aff;
  transition-duration: 0.2s;
}
.selection-tab {
  transform: scale(1.05);
  box-shadow: 0 20px 15px rgba(0, 0, 0, 0.5);
  border-style: solid;
  border-color: #fad506;
}
.container .cookieCard:hover {
  transform: scale(1.05);
  box-shadow: 0 20px 15px rgba(0, 0, 0, 0.5);
}
/* .selection-tab{
  border-style: solid;
  background-color: black;
} */
.text-justify {
  text-align: justify;
}
.progress-wrap {
  width: 50%;
}
.progress-container {
  width: 200px; /* Adjust this as needed */
  height: 200px;
  padding-bottom: 100%; /* Creates a square aspect ratio */
  position: relative;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .progress-wrap {
    width: 100%;
  }
}
.modal-bg {
  border-style: solid;
  border-color: #fff;
  color: #fff;
  padding: 20px;
  background: linear-gradient(to right, rgb(137, 104, 255), rgb(175, 152, 255));
}
.text-line {
  line-height: 1.7rem;
}
.full-icon {
  cursor: pointer;
}
.confettie-wrap {
  background-color: darkslateblue;
}

.confettie-wrap h3 {
  margin: 0;
  color: white;
  letter-spacing: 2px;
}

.user-img {
  width: 64px;
  height: 64px;
  border-radius: 50em;
}
.sidebar-absolute {
  position: absolute;
  height: 100vh;
}
.sidebar-nav span {
  margin-left: 1.4rem;
}
span.white {
  svg {
    color: white !important;
  }
}
span.red {
  svg {
    color: red !important;
  }
}
span.green {
  svg {
    color: green !important;
  }
}
span.black {
  svg {
    color: black !important;
  }
}
.dark-mode {
  .header {
    background-color: #000000 !important;
    color: #ffffff !important;
    a,
    svg {
      color: #ffffff !important;
    }
  }
  .header-nav {
    ul {
      li {
        a,
        svg {
          color: #000000 !important;
        }
      }
    }
  }
}
.dark-content {
  .card {
    background: #46546c;
    color: #ffffff;
    a,
    table,
    .text-black {
      color: #ffffff !important;
    }
    .bg-selected,
    .bg-trending {
      color: #000000;
    }
    .dropdown-menu {
      li {
        a {
          color: #000000 !important;
        }
      }
    }
  }
}
.new-input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: var(--cui-input-focus-color, rgba(44, 56, 74, 0.95));
    background-color: var(--cui-input-focus-bg, #fff);
    border-color: var(--cui-input-focus-border-color, #998fed);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25);
  }
}
.tooltip {
  position: fixed !important;
}
.progress-wrap {
  span {
    display: none;
  }
}
.chat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  .chatIcon {
    padding: 15px 15px 12px;
    background: #000000;
    border-radius: 50%;
    margin-bottom: 0;
    float: right;
    &:hover {
      cursor: pointer;
      background: #555555;
    }
    svg {
      color: #ffffff;
    }
  }
  .chat-open {
    width: 376px;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    background: #ffffff;
    .chat-header {
      background-color: rgb(0, 65, 94);
      display: flex;
      color: #ffffff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 15px;
      align-items: center;
    }
    .chat-body {
      background: #ffffff;
      ul {
        list-style: none;
        padding: 15px 15px 5px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0;
        li {
          padding: 5px 10px;
          border-radius: 5px;
          background: #e9e9e9;
          font-size: 11px;
          margin-right: 10px;
          margin-bottom: 10px;
          transition: all 0.2s ease-in;
          &:hover {
            cursor: pointer;
            background: #cccccc;
          }
        }
      }
      .messages {
        height: 50vh;
        overflow-y: scroll;
        padding:0 15px 15px;
        .left-bubble {
          display: flex;
          float: left;
          margin-bottom: 20px;
          .message {
            flex: 1;
            position: relative;
            min-height: 32px;
            word-break: break-word;
            box-sizing: border-box;
            background-color: rgb(234, 240, 246);
            color: rgb(66, 91, 118);
            border-radius: 0px 8px 8px;
            padding: 8px 16px;
            margin-left: 10px;
            font-size: 14px;
          }
        }
        .right-bubble {
          float: right;
          margin-bottom: 20px;
          width: 100%;
          .message {
            flex: 1;
            position: relative;
            min-height: 32px;
            word-break: break-word;
            box-sizing: border-box;
            background-color: rgb(234, 240, 246);
            color: rgb(66, 91, 118);
            border-radius: 8px 0px 8px 8px;
            padding: 8px 16px;
            margin-right: 10px;
            font-size: 14px;
            float: right;
          }
          img {
            float: right;
          }
        }
      }
    }
    .chat-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      border-top: 1px solid #e9e9e9;
      .contenteditable[contenteditable][placeholder]:empty {
        overflow-y: hidden;
        outline: none;
        &::after {
          display: block;
          content: attr(placeholder);
          position: relative;
          color: rgb(123, 152, 182);
          background-color: transparent;
          cursor: text;
          user-select: none;
          font-size: 16px;
          line-height: 22px;
          height: 22px;
        }
      }
      .contenteditable {
        overflow-x: hidden;
        margin-right: 20px;
        flex: 1;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
.no-click {
  pointer-events: none;
}
.pointer {
  &:hover {
    cursor: pointer;
  }
}
